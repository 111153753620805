<template>
  <modal @close="hideAndResetModal" :show.sync="isModalOpened" class="add-patients-for-event-modal">
    <h1 slot="header" class="modal-title pt-2 pl-1" id="modal-title-default">Add Patients For Event</h1>
    <div slot="default" v-loading="loader">
      <validation-observer ref="addPatientsForEventModal" v-slot="{ handleSubmit }">
        <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)"
              @keypress.enter.prevent>
          <div class="row">
            <span class="col-6 date-text">Date</span>
            <span class="col-6 date-text">Event</span>
          </div>
          <div class="row mb-3">
            <div class="col-6 pr-0 pt-2">
              {{ selectedEventDate | formatDate }}
            </div>
            <div class="col-6 pt-2 overflow-x-hidden event-title">
              {{ event.title }}
            </div>
          </div>
          <div class="search-patient-input">
            <base-input
              v-model="request.patientName"
              :append-icon="getSearchOrClearIcon"
              :is-icon-clickable="allowIconClickable"
              @iconClicked="clearPatients"
              label="Search Patient"
              placeholder="Patient Name"
              @keyup.enter="getPatients">
            </base-input>
          </div>
          <validation-provider v-slot="{ errors }" name="patient" :rules="rules"
                               :custom-messages="patientsRequiredCustomMessage">
            <el-checkbox-group v-model="patientIds" class="checkbox-group">
              <el-checkbox class="col-12 w-100" v-for="patient in response.patients" :label="patient.id">
                <el-tooltip
                  class="item" effect="dark"
                  :content="hoverPatientInfo(patient)"
                  placement="bottom-start"
                  :visible-arrow="false"
                >
                  <span>{{ patient.first_name + ' ' + patient.last_name }}</span>
                </el-tooltip>
              </el-checkbox>
            </el-checkbox-group>
            <slot name="error">
              <div v-if="errors[0]" class="invalid-feedback d-block">
                {{ errors[0] }}
              </div>
            </slot>
            <div v-if="!response.patients.length" class="d-flex align-items-center h-100 w-100">
              <span class="empty-text">No Patient</span>
            </div>
          </validation-provider>
          <div class="float-right mt-4">
            <base-button type="secondary" class="ml-auto btn-gray" @click="hideAndResetModal">
              Cancel
            </base-button>
            <base-button native-type="submit" type="submit" class="btn-primary-action">
              Add
            </base-button>
          </div>
        </form>
      </validation-observer>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import Helper from "@/util/globalHelpers";

export default {
  name: "AddPatientForEventsRSVPModal",
  props: ['showModal', 'selectedEvent', 'selectedEventDate'],
  data() {
    return {
      loader: false,
      isModalOpened: false,
      request: {
        patientName: '',
        date: '',
      },
      response: {
        patients: []
      },
      patientIds: [],
      event: {
        id: 0,
        title: '',
        date: '',
      },
      rules: {
        required: true,
      },
    }
  },
  filters: {
    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate: function (date) {
      return date ? moment(date).format('MMM D, YYYY') : ''
    },
  },
  watch: {
    showModal(newVal) {
      this.isModalOpened = newVal
    },

    selectedEvent(newVal) {
      this.event.id = newVal.id
      this.event.title = newVal.event_title
    }
  },
  computed: {
    /**
     * Custom message for patient select
     * @returns {{required: (string)}}
     */
    patientsRequiredCustomMessage() {
      return {
        required: this.request.patientName ? 'Please select at least one patient' : 'Please search and select at least one patient'
      }
    },

    /**
     * Get search or clear icon based on input
     * @returns {string}
     */
    getSearchOrClearIcon() {
      return this.request.patientName ? 'fas fa-times' : 'fas fa-search'
    },

    /**
     * Check and return if icon is clickable
     * @returns {boolean}
     */
    allowIconClickable() {
      return !!this.request.patientName
    },
  },
  methods: {
    /**
     * Hide patient events add modal
     */
    hideAndResetModal() {
      this.resetForm()
      this.$emit('togglePatientEventsAddModal')
    },

    /**
     * Get patient information
     * @param patient
     * @returns {string}
     */
    hoverPatientInfo(patient) {
      let info = ''
      if (patient.dob !== '' && patient.dob != null) {
        if (!isNaN(Date.parse(patient.dob))) {
          const date = new Date(patient.dob)
          info = Helper.extractAndFormatDate(date.toDateString(), false)
        }
      }
      info += (patient.mrn_number !== '' && patient.mrn_number != null) ? ', ' + patient.mrn_number : ''
      return info
    },

    /**
     * Submit the Form and hide it
     */
    submitForm() {
      let vm = this
      vm.loader = true
      const payload = {
        eventId: vm.event.id,
        patientIds: vm.patientIds,
        eventDate: moment(this.selectedEventDate).format('MM/DD/YYYY'),
        eventTitle: vm.event.title
      }
      vm.$store.dispatch('_storePatientsEvent', payload)
      .then(response => {
        vm.hideAndResetModal()
        this.$emit('update-patient-events')
        vm.$notify.success({
          title: 'Patients Event',
          message: 'The selected patients have been added to the event.'
        })
      })
      .catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Patients Event',
          message: message
        })
      })
      .finally(() => {
        vm.loader = false
      })
    },

    /**
     * Clear patients
     */
    clearPatients() {
      this.request.patientName = ''
      this.response.patients = []
    },

    /**
     * Get requested patients based on search
     */
    getPatients() {
      let vm = this

      //Condition to check if search name is empty
      if (!vm.request.patientName) {
        vm.response.patients = []
        return
      }

      vm.loader = true
      const params = {
        name: vm.request.patientName
      }
      vm.$store.dispatch('_getRequestedPatientsForEvents', params)
        .then(response => {
          vm.response.patients = []
          vm.response.patients = [...response.data.data]
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Patients',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Reset the form
     */
    resetForm() {
      let vm = this
      vm.request.patientName = ''
      vm.response.patients = []
      vm.patientIds = []
      this.$refs.addPatientsForEventModal.reset()
    },
  }
}
</script>

<style scoped>
.event-title {
  border-right: 15px solid white;
}

.add-patients-for-event-modal >>> .modal-content,
.add-patients-for-event-modal >>> .modal-header,
.add-patients-for-event-modal >>> .modal-body {
  width: 600px;
}

.add-patients-for-event-modal >>> .modal-dialog {
  justify-content: center;
}

</style>

<style scoped lang="scss">

.search-patient-input {
  ::v-deep .input-group-text {
    padding: 0 0.75rem;
    color: #172B4D;
  }

  ::v-deep .form-control {
    border-right-color: transparent !important;
  }
}

.empty-text {
  color: #909399;
  line-height: 60px;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.checkbox-group {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.date-text {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

</style>
