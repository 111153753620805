<template>
  <div v-loading="loaders.exportReport" class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <AddPatientForEventsRSVPModal
    :show-modal="showPatientEventsAddModal"
    :selected-event="event"
    :selected-event-date="selectedEventDate"
    @update-patient-events="getPatientEvents"
    @togglePatientEventsAddModal="togglePatientEventsAddModal"
    />
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Support Group Attendance</h1>
      <base-button class="float-right mt-2 btn-secondary-action title-btn-padding button-font" @click="togglePatientEventsAddModal">
        Add Patient
      </base-button>
    </div>
    <div v-loading="loaders.response" class="mt-4 cardStyle">
      <el-table
        :data="response.patientEvents"
        row-key="id"
        class="table-responsive table-flush py-4"
        header-row-class-name="thead-light"
      >
        <el-table-column label="PATIENT NAME" width="320px">
          <template slot-scope="scope">
            <router-link :to="{name: 'PatientDetail', params: {id: scope.row.patient_id}}">
              <span class="color-maroon">{{ scope.row.patient.first_name + ' ' + scope.row.patient.last_name }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="EVENT TITLE">
          <template slot-scope="scope">
            <span>{{ scope.row.event_title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="EVENT DATE" width="250px">
          <template slot-scope="scope">
            <span>{{ scope.row.event_date | formatDate }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <seminar-surgery-footer :pagination="pagination" @changePage="changePage"
                                  @changedPageLength="changedPageLength"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "../SeminarSurgeryFooter";
import {Table, TableColumn} from "element-ui";
import moment from "moment-timezone";
import AddPatientForEventsRSVPModal from "@/views/Pages/Seminar2Surgery/EventsRSVP/AddPatientForEventsRSVPModal";
import BackButton from "@/components/Router/BackButton";

export default {
  name: "EventsRSVP",
  components: {
    SeminarSurgeryFooter,
    AddPatientForEventsRSVPModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BackButton
  },
  data() {
    return {
      showPatientEventsAddModal: false,
      loaders: {
        response: false,
        exportReport: false
      },
      response: {
        patientEvents: [],
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
      event: {
        event_title: ''
      },
    }
  },
  computed: {
    selectedEventDate() {
      return atob(this.$route.params.eventDate)
    }
  },
  mounted() {
    this.getPatientEvents()
  },
  filters: {
    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate: function (date) {
      return date ? moment(date).format('MMM D, YYYY') : ''
    },
  },
  methods: {
    togglePatientEventsAddModal() {
      this.showPatientEventsAddModal = !this.showPatientEventsAddModal
    },

    /**
     * Assign the Params and return it
     * @returns {{perPage: number, page: number}}
     */
    assignParams() {
      let vm = this
      return {
        page: vm.pagination.page,
        perPage: vm.pagination.perPage,
        eventId: vm.$route.params.eventId,
        eventDate: vm.selectedEventDate
      }
    },

    /**
     * Save the API response object
     * @param response
     */
    saveApiResponse(response) {
      let vm = this
      vm.response.patientEvents = []
      vm.response.patientEvents = [...response.data.data.patientAcceptedEvents.data]
      vm.pagination.currentPage = response.data.data.patientAcceptedEvents.current_page
      vm.pagination.lastPage = response.data.data.patientAcceptedEvents.last_page
      vm.pagination.perPage = response.data.data.patientAcceptedEvents.per_page
      vm.pagination.page = response.data.data.patientAcceptedEvents.current_page
      vm.pagination.total = response.data.data.patientAcceptedEvents.total
      vm.event = response.data.data.event
    },

    /**
     * Get all Patient Events
     */
    getPatientEvents() {
      let vm = this
      vm.loaders.response = true
      const params = vm.assignParams()
      vm.$store.dispatch('_getPatientEvents', params)
        .then(response => {
          vm.saveApiResponse(response)
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Patient Events',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Change page in the listing
     * @param page
     */
    changePage(page) {
      if (this.pagination.page != page) {
        this.pagination.page = page
        this.getPatientEvents()
      }
    },

    /**
     * Change page length in the listing
     * @param pageLength
     */
    changedPageLength(pageLength) {
      this.pagination.page = 1
      this.pagination.perPage = pageLength
      this.getPatientEvents()
    },
  }
}
</script>

<style scoped>

</style>
